import request from "@/utils/request";

// StockTransferOrder
export function stockTransferOrderList(params) {
  return request({ url: `/stock_transfer_orders/`, method: "get", params });
}

export function stockTransferOrderRetrieve(params) {
  return request({ url: `/stock_transfer_orders/${params.id}/`, method: "get", params });
}

export function stockTransferOrderCreate(data) {
  return request({ url: `/stock_transfer_orders/`, method: "post", data });
}

export function stockTransferOrderMaterials(params) {
  return request({ url: `/stock_transfer_orders/${params.id}/stock_transfer_materials/`, method: "get", params });
}

export function stockTransferOrderVoid(data) {
  return request({ url: `/stock_transfer_orders/${data.id}/void/`, method: "post", data });
}

// StockTransferMaterial
export function stockTransferMaterialList(params) {
  return request({ url: `/stock_transfer_materials/`, method: "get", params });
}

// 调拨货品
export function transferMaterials(params) {
  return request({ url: `/materials/options/`, method: "get", params });
}

// LocationTransferOrder
export function locationTransferOrderList(params) {
  return request({ url: `/location_transfer_orders/`, method: "get", params });
}

export function locationTransferOrderRetrieve(params) {
  return request({ url: `/location_transfer_orders/${params.id}/`, method: "get", params });
}

export function locationTransferOrderNumber(params) {
  return request({ url: `/location_transfer_orders/number/`, method: "get", params });
}

export function locationTransferOrderCreate(data) {
  return request({ url: `/location_transfer_orders/`, method: "post", data });
}

export function locationTransferOrderVoid(data) {
  return request({ url: `/location_transfer_orders/${data.id}/void/`, method: "post", data });
}

export function locationTransferOrderMaterials(params) {
  return request({ url: `/location_transfer_orders/${params.id}/location_transfer_materials/`, method: "get", params });
}

// 品质调整
export function qualityAdjustOrderList(params) {
  return request({ url: `/quality_adjust_orders/`, method: "get", params });
}

export function qualityAdjustOrderRetrieve(params) {
  return request({ url: `/quality_adjust_orders/${params.id}/`, method: "get", params });
}

export function qualityAdjustOrderNumber(params) {
  return request({ url: `/quality_adjust_orders/number/`, method: "get", params });
}

export function qualityAdjustOrderCreate(data) {
  return request({ url: `/quality_adjust_orders/`, method: "post", data });
}

export function qualityAdjustOrderVoid(data) {
  return request({ url: `/quality_adjust_orders/${data.id}/void/`, method: "post", data });
}

export function qualityAdjustOrderMaterials(params) {
  return request({ url: `/quality_adjust_orders/${params.id}/quality_adjust_materials/`, method: "get", params });
}
